import {
    // ElementRef, 
    Injectable,
    // Renderer2 
} from '@angular/core';
import { BehaviorSubject, Observable, map, of } from 'rxjs';
import { Deviz, Nomenclator, Lucrare, Proprietar, Masina } from './models';
import { IonContent, MenuController, ToastController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import {
    BreakpointObserver,
    BreakpointState
} from '@angular/cdk/layout';
import { MatDialog } from '@angular/material/dialog';
import { SavingDialogComponent } from '../components/dialogs/saving-dialog/saving-dialog.component';
import { MasinaListFilters } from 'src/app/masina/components/masina-list-filters/masina-list-filters.component';
import { NomenclatorList } from 'src/app/nomenclator/components/nomenclator-list/nomenclator-list.component';
import { PieseList } from 'src/app/lucrare/components/piese-list/piese-list.component';
import { LoadingController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class InterfaceService {
    currentDialogRef: any;
    currentLoading: any;
    content!: IonContent;
    scrollTarget: any;

    constructor(
        private menuCtrl: MenuController,
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        private breakpointObserver: BreakpointObserver,
        private dialog: MatDialog,
        private _toastController: ToastController,
        private _loadingController: LoadingController
        // private _renderer: Renderer2,
        // private el: ElementRef,
    ) {

    }

    setIonContent(content: IonContent): void {
        this.content = content;
    }

    setScrollTarget(scrollTarget: any): void {
        this.scrollTarget = scrollTarget;
    }

    getIonContent(): IonContent {
        return this.content;
    }

    scrollToElement() {
        // const yOffset = this.scrollTarget.nativeElement.offsetTop - 56; // 56 is the height of ion-header (assuming standard height)
        // console.log('scrolling again', yOffset);
        // this.content.scrollToPoint(0, yOffset, 300).then(); // 300ms animation duration
    }

    blockScroll(): void {
        this.content.scrollY = true;
        const scrollableContent1 = document.querySelector<HTMLElement>('.page');
        const scrollableContent2 = document.querySelector<HTMLElement>('.pageContent');
        // if(scrollableContent1 && scrollableContent2) {
        //     console.log('disable scroll on page and pagecontent1');
        //     scrollableContent1.style.position = 'absolute';
        //     scrollableContent1.style.top = '0';
        //     scrollableContent1.style.bottom = '0';
        //     scrollableContent1.style.left = '0';
        //     scrollableContent1.style.right = '0';
        //     scrollableContent1.style.overflow = 'hidden';

        //     scrollableContent2.style.position = 'absolute';
        //     scrollableContent2.style.top = '0';
        //     scrollableContent2.style.bottom = '0';
        //     scrollableContent2.style.left = '0';
        //     scrollableContent2.style.right = '0';
        //     scrollableContent2.style.overflow = 'hidden';
        // }
    }

    scrollTop(): void {
        window.scrollTo(0, 0);
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
        if (this.content) {
          this.content.scrollToTop(); // Use Ionic's scrollToTop method
        }
    }

    enableScroll(): void {
        this.content.scrollY = false;
    }

    /*
        SECONDARY ROUTE RELATED
    */

    // qp: proprietar_id
    async proprietarCreate(prefill_nume_proprietar?: string): Promise<void> {
        this._router.navigate([{ outlets: { secondary: 'proprietari-create' } }], { skipLocationChange: false, queryParams: { prefill: prefill_nume_proprietar || null } });
        this.openMenu();
    }

    // qp: proprietar_id
    async proprietarList(prefill?: string, merge_id?: string): Promise<void> {
        console.log('avem cu ce sa deschidem lista de proprietari', prefill);

        this._router.navigate([{ outlets: { secondary: 'proprietari-create-list' } }], 
            { 
                queryParams: { merge_id_proprietar: merge_id, prefill: prefill}, 
                skipLocationChange: false 
            });
        this.openMenu();
    }

    // qp: masina_id
    // also if proprietar_id existent on prefill, saves the car and links to it
    async masinaCreate(prefill_serie_or_numar?: string, prefill_proprietar_id?: string): Promise<void> {
        let isSerie = false;
        if (prefill_serie_or_numar && prefill_serie_or_numar?.length > 10) {
            isSerie = true;
        }

        this._router.navigate([{
            outlets: {
                secondary: 'masini-create',
            }
        }], {
            queryParams: { [!isSerie ? 'prefill_numar' : 'prefill_serie']: prefill_serie_or_numar, proprietar_id: prefill_proprietar_id },
            skipLocationChange: false
        });
        this.openMenu();

    }

    // qp: masina_id
    async masinaList(prefill_serie_or_numar?: string, masina_id?: string): Promise<void> {
        this.closeDialog();
        console.log('mergem cu serie si numar', prefill_serie_or_numar);
        let isSerie = false;
        if (prefill_serie_or_numar && prefill_serie_or_numar.length > 10) {
            isSerie = true
        }

        this._router.navigate([{
            outlets: {
                secondary: 'masini-list',
            }
        }], {
            queryParams: { [!isSerie ? 'prefill_numar' : 'prefill_serie']: prefill_serie_or_numar, merge_id_masina: masina_id },
            skipLocationChange: false
        });
        this.openMenu();
    }

    // qp: lucrare_id
    async piesaCreate(prefill_masina_id: string): Promise<void> {
        this.closeDialog();
        this._router.navigate([{ outlets: { secondary: 'piese-create' } }], { skipLocationChange: false, queryParams: { masina_id: prefill_masina_id } });
        await this.menuCtrl.open('second-menu');
    }

    // qp: operation_id
    async openNomenclatorCreate(prefill_operation_name?: string | null): Promise<void> {
        this.closeDialog();
        this.clearMenu();

        this._router.navigate([{ outlets: { secondary: 'nomenclator-create' } }], { skipLocationChange: false, queryParams: { prefill: prefill_operation_name } });
        await this.menuCtrl.open('second-menu');
    }

    // qp: firebase_uid
    async addFirebaseUID() {
        this._router.navigate([{ outlets: { secondary: 'add-firebase' } }], { skipLocationChange: false });
        await this.menuCtrl.open('second-menu');
    }

    isSecondaryOutletActive(): boolean {
        return this._router.url.includes('secondary');
    }

    removeQueryParams(qp: string[]): void {
        const queryParams = { ...this._activatedRoute.snapshot.queryParams };

        qp.forEach(q => {
            delete queryParams[q];
        });

        // Navigate without the parameter
        this._router.navigate([], {
            relativeTo: this._activatedRoute,
            queryParams: queryParams,
            queryParamsHandling: 'merge', // Preserve other query params
        });
    }

    /*
        MENU RELATED
    */

    async openMenu(): Promise<void> {
        await this.menuCtrl.open('second-menu')
    }

    async clearMenu(): Promise<void> {
        await this.menuCtrl.close('second-menu');
    }

    /*
        DIALOG RELATED
    */
    messageDialog(message: string): void {
        this.closeDialog();
        this.clearMenu();

        this.currentDialogRef = this.dialog.open(SavingDialogComponent, {
            data: { message: message },
            disableClose: false
        });
    }

    openNomenclatorList(): Observable<any> {
        this.closeDialog();
        this.clearMenu();

        this.currentDialogRef = this.dialog.open(NomenclatorList, {
            data: { dialog: true },
            height: '80%'
        });

        return this.currentDialogRef.afterClosed().pipe(
            map(result => result)
        );
    }

    openPieseList(masina_id?: string): Observable<any> {
        this.closeDialog();
        this.clearMenu();

        this.currentDialogRef = this.dialog.open(PieseList, {
            data: { fk_masina_id: masina_id },
            height: '80%',
            width: '80%',
            panelClass: 'hide-advance-filters'
        });

        // const instance = this.currentDialogRef.componentInstance;
        // instance.filters = {
        //     fk_masina_id: masina_id
        // };

        return this.currentDialogRef.afterClosed().pipe(
            map(result => {
                return result
            })
        );
    }

    // filters
    openCarFilters(prefillFilters: any): Observable<any> {
        this.closeDialog();
        this.clearMenu();
        this.currentDialogRef = this.dialog.open(MasinaListFilters, {
            data: prefillFilters,
            disableClose: true
        });

        return this.currentDialogRef.afterClosed().pipe(
            map(result => result)
        );
    }

    closeDialog(payload?: any): void {
        if (this.currentDialogRef)
            this.currentDialogRef.close(payload);
        this.currentDialogRef = null;
    }

    /*
        TOAST RELATED
    */
    async presentToast(message: string, color?: 'success' | 'danger' | 'warn') {
        const toast = await this._toastController.create({
            message: message,
            duration: 3000,
            position: 'top',
            color: color || 'success',
            cssClass: 'text-xl'
        });

        await toast.present();
    }


    /*
        UTILS
    */

    copyLink(text: string): void {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(text).then(() => {
                this.presentToast('Textul a fost copiat');
            }).catch(err => {
                console.error('Failed to copy text: ', err);
            });
        } else {
            // Fallback for older browsers
            try {
                var textArea = document.createElement("textarea");
                textArea.value = text;
                // Avoid scrolling to bottom
                textArea.style.top = "0";
                textArea.style.left = "0";
                textArea.style.position = "fixed";

                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();

                var successful = document.execCommand('copy');
                var msg = successful ? 'successful' : 'unsuccessful';

                document.body.removeChild(textArea);
                this.presentToast('Textul a fost copiat');
            } catch (err) {
                console.error('Fallback: Oops, unable to copy', err);
            }
        }
    }

    /*
        LOADER
    */

    async showLoader(): Promise<void> {
        this.currentLoading = await this._loadingController.create({
            // message: 'Dismissing after 3 seconds...',
            // duration: 3000,
        });

        this.currentLoading.present();
    }

    // async dismissLoader(): Promise<void> {
    //     setTimeout(() => {
    //         console.log('call dismiss');

    //         if(this.currentLoading?.dismiss) {
    //             this.currentLoading.dismiss();
    //             console.log('dismiss loading object 1');
    //             setTimeout(() => {
    //                 console.log('dismiss loading object 2');
    //                 this.currentLoading.dismiss();
    //             }, 500);
    //         }

    //         console.log('dismiss loading general 1');
    //         // this._loadingController.dismiss();
    //         // setTimeout(() => {
    //         //     console.log('dismiss loading general 2');
    //         //     this._loadingController.dismiss();
    //         // }, 500);

    //         // add function: checkDismissed()
    //     }, 500);
    // }

    async dismissLoader() {
        // Instead of directly closing the loader like below line
        // return await this.loadingController.dismiss();

        this.checkAndCloseLoader();

        // sometimes there's delay in finding the loader. so check if the loader is closed after one second. if not closed proceed to close again
        setTimeout(() => this.checkAndCloseLoader(), 1000);
        setTimeout(() => this.checkAndCloseLoaderTwo(), 2000);
    }

    async checkAndCloseLoader() {
        // Use getTop function to find the loader and dismiss only if loader is present.
        const loader = await this._loadingController.getTop();
        // if loader present then dismiss
        if (loader !== undefined) {
            await this._loadingController.dismiss();
        }

        if(this.currentLoading?.dismiss) {
            console.log('dismiss', this.currentLoading);
            this.currentLoading.dismiss();
        }
    }

    async checkAndCloseLoaderTwo(): Promise<void> {
        await this._loadingController.dismiss();
        console.log('dismiss general');

        if(this.currentLoading?.dismiss) {
            console.log('dismiss', this.currentLoading);
            this.currentLoading.dismiss();
        }

        const loader = await this._loadingController.getTop();
        if (loader) {
            console.log('dismiss', loader);
            await loader.dismiss()
        }
    }

    /*
        DOCUMENT RELATED
    */

    // FUCKING LOST CAUSE
    markItem(item: any, color: 'green' | 'red'): void { }
    // for when adding a new item or highlighting error items that have value 0 for example
    // markItem(item: any, color: 'green' | 'red'): void {
    //     const lucrare_id = item.lucrare_id;
    //     // item.isNew = true;
    //     // console.log('mark it for mortii matii', item);
    //     // this._cdr.detectChanges();

    //     setTimeout(() => {
    //         // item.isNew = true;
    //         // console.log('mark it for mortii matii', item);
    //         // this._cdr.detectChanges();
    //     }, 1000);

    //     this.newItemId = `${item.operation_id}_desktop`;

    //     // Ensure the class is added after the view updates
    //     setTimeout(() => {
    //         this.ngAfterViewInit();
    //     }, 500);

    //     return;
    //     console.log('attempt', lucrare_id);
    //     const indexMateriale = this.deviz.operatiuni_materiale.findIndex((item: any) => item.lucrare_id === lucrare_id);
    //     if (indexMateriale !== -1) {
    //         // If found, remove the object from the array
    //         const [item] = this.deviz.operatiuni_materiale.splice(indexMateriale, 1);
    //         // And unshift it to the start of the array
    //         this.deviz.operatiuni_materiale.unshift(item);

    //         setTimeout(() => {
    //             const element_mobile = document.getElementById(lucrare_id + '_mobile');
    //             const element_desktop = document.getElementById(lucrare_id + '_desktop');
    //             if (element_desktop) {
    //                 element_desktop.style.border = '1px solid greenyellow';
    //                 // setTimeout(() => {         
    //                 //   element_desktop.style.border = '';
    //                 // }, 3000);
    //             }

    //             if (element_mobile) {
    //                 element_mobile.style.border = '1px solid greenyellow';
    //                 // setTimeout(() => {
    //                 //   element_mobile.style.border = '';
    //                 // }, 3000);
    //             }
    //         }, 200);

    //     } else {
    //         const indexManopera = this.deviz.operatiuni_manopera.findIndex((item: any) => item.operation_id === lucrare_id);

    //         setTimeout(() => {
    //             console.log('attemp2', document.getElementById(lucrare_id + '_mobile'), document.getElementById(lucrare_id + '_desktop'));
    //             const element_mobile = document.getElementById(lucrare_id + '_mobile');
    //             const element_desktop = document.getElementById(lucrare_id + '_desktop');
    //             if (element_desktop) {
    //                 // const escapedId = `${lucrare_id}_desktop`.replace(/([:.\[\],=])/g, '\\$1');
    //                 // const element = this.el.nativeElement.querySelector(`#${escapedId}`);
    //                 // if (element) {
    //                 //   this._renderer.setStyle(element, 'your-css-property', 'your-value');
    //                 // }

    //                 // element_desktop.classList.add('border');
    //                 // element_desktop.style.border = '1px solid greenyellow !important';

    //                 const newItemId = `${lucrare_id}_desktop`;
    //                 const newElement = document.getElementById(newItemId);
    //                 if (newElement) {
    //                     console.log('add renderer class', newElement)
    //                     this._renderer.addClass(newElement, 'border');
    //                     // setTimeout(() => {
    //                     //   this._renderer.removeClass(newElement, 'new-item');
    //                     // }, 3000); // Adjust the timeout as needed
    //                 }

    //                 console.log('change desktop!!!', element_desktop);
    //                 // setTimeout(() => {     
    //                 //   element_desktop.style.border = '';
    //                 // }, 3000);
    //             }

    //             if (element_mobile) {
    //                 element_mobile.style.border = '1px solid greenyellow';
    //                 // setTimeout(() => {
    //                 //   element_mobile.style.border = ''; 
    //                 // }, 3000);
    //             }
    //         }, 200);
    //     }
    // }

    // escapeCSSId(id: string): string {
    //     return id.replace(/([!"#$%&'()*+,.\/:;<=>?@[\\\]^`{|}~])/g, '\\$1');
    // }

    // highlightErrorItem(item: any): void {
    //     setTimeout(() => {
    //         const element_mobile = document.getElementById(item.lucrare_id + '_mobile');
    //         const element_desktop = document.getElementById(item.lucrare_id + '_desktop');
    //         if (element_desktop) {
    //             element_desktop.style.border = '1px solid red';
    //             setTimeout(() => {
    //                 element_desktop.style.border = '';
    //             }, 3000);
    //         }

    //         if (element_mobile) {
    //             element_mobile.style.border = '1px solid red';
    //             setTimeout(() => {
    //                 element_mobile.style.border = '';
    //             }, 3000);
    //         }
    //     }, 1000);
    // }

    // ngAfterViewInit() {
    //     console.log('attempt', this.newItemId);
    //     if (this.newItemId) {
    //         const newElement = document.getElementById(this.newItemId);
    //         console.log('attempt 3', newElement);
    //         if (newElement) {
    //             this._renderer.addClass(newElement, 'border');
    //             this._renderer.setStyle(newElement, 'border', '1px solid yellow');
    //             console.log('attempt 4', newElement);
    //         }
    //         this.newItemId = null;
    //     }
    // }
}